var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-expansion-panels",
        [
          _c(
            "v-expansion-panel",
            [
              _c(
                "v-expansion-panel-header",
                {
                  staticClass: "text-h6 py-2",
                  scopedSlots: _vm._u([
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var onTooltip = ref.on
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {},
                                          Object.assign({}, onTooltip)
                                        ),
                                        [
                                          _vm._v(
                                            "\n                $expand\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [_c("span", [_vm._v("See details")])]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("v-text-field", {
                    staticClass: "input-max-width mr-6 mt-3",
                    attrs: {
                      disabled: _vm.disabled,
                      "error-messages": _vm.campaignNameErrors,
                      dense: "",
                      label: "Campaign Name",
                      "data-wsc-autocreate": "true",
                      "data-wsc-lang": "en_US"
                    },
                    on: {
                      input: function($event) {
                        return _vm.$v.campaignName.$touch()
                      },
                      blur: function($event) {
                        _vm.$v.campaignName.$touch()
                        if (_vm.campaignName) {
                          _vm.setCampaignValue("campaignName")
                        }
                      }
                    },
                    nativeOn: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    },
                    model: {
                      value: _vm.campaignName,
                      callback: function($$v) {
                        _vm.campaignName = $$v
                      },
                      expression: "campaignName"
                    }
                  }),
                  _c("v-select", {
                    staticClass: "input-max-width mr-6",
                    attrs: {
                      disabled: _vm.disabled,
                      dense: "",
                      "hide-details": "",
                      items: _vm.customerContacts,
                      outlined: "",
                      label: "Contact",
                      color: "primary",
                      loading: _vm.customerContactsLoading
                    },
                    on: {
                      blur: function($event) {
                        return _vm.setCampaignValue("campaignOnBehalfOfNowwId")
                      }
                    },
                    nativeOn: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    },
                    model: {
                      value: _vm.onBehalfOfNowwId,
                      callback: function($$v) {
                        _vm.onBehalfOfNowwId = $$v
                      },
                      expression: "onBehalfOfNowwId"
                    }
                  }),
                  _vm.displayCustomerName
                    ? _c("span", { staticClass: "mr-4" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getCampaignTenantName()) +
                            "\n        "
                        )
                      ])
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "span",
                    { staticClass: "d-flex justify-end" },
                    [
                      _vm.campaign && _vm.campaign.targetAudienceStatus
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                color: _vm.$_dashMixin_taStatusColor(
                                                  _vm.campaign
                                                    .targetAudienceStatus
                                                ),
                                                left: ""
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$_dashMixin_getTAIcon(
                                                    _vm.campaign
                                                      .targetAudienceStatus
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2531332577
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.campaign.targetAudienceStatusDisplay ||
                                      "Not selected"
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.campaign && _vm.campaign.nowwOrderNumber
                        ? _c(
                            "span",
                            { staticClass: "mr-4" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.campaign.nowwOrderNumber) +
                                  " "
                              ),
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    color: _vm.$_dashMixin_fileTypeColor(
                                      _vm.campaign.targetAudienceFileType
                                    ),
                                    dark: _vm.$_dashMixin_fileTypeColorDark(
                                      _vm.campaign.targetAudienceFileType
                                    )
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.campaign.targetAudienceFileType
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.campaign &&
                      _vm.campaign.campaignStatusDisplay === "Draft"
                        ? _c(
                            "span",
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: {
                                    persistent: _vm.taSelectionRequired
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var onDialog = ref.on
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var onTooltip = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mr-4",
                                                                attrs: {
                                                                  color: _vm
                                                                    .campaign
                                                                    .targetAudienceId
                                                                    ? "primary"
                                                                    : "success",
                                                                  disabled: _vm.isLoading()
                                                                }
                                                              },
                                                              Object.assign(
                                                                {},
                                                                onTooltip,
                                                                onDialog
                                                              )
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    left: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        mdi-bullseye-arrow\n                      "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    _vm.campaign
                                                                      .targetAudienceId
                                                                      ? "Change"
                                                                      : "Select"
                                                                  ) +
                                                                  " Target Audience\n                    "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.campaign
                                                        .targetAudienceId
                                                        ? "P.S. A Target Audience can only be changed while Campaign status is Draft"
                                                        : "P.S. A Target Audience must be selected before scheduling"
                                                    ) + " "
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2334153772
                                  ),
                                  model: {
                                    value: _vm.showTADialog,
                                    callback: function($$v) {
                                      _vm.showTADialog = $$v
                                    },
                                    expression: "showTADialog"
                                  }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "text-h6 grey lighten-2",
                                          attrs: { "primary-title": "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Target Audiences\n                  "
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.campaign
                                                      .nowwOrderNumber,
                                                  expression:
                                                    "campaign.nowwOrderNumber"
                                                }
                                              ],
                                              staticClass: "font-italic"
                                            },
                                            [
                                              _vm._v(
                                                " Current:\n                    "
                                              ),
                                              _vm.campaign &&
                                              _vm.campaign.targetAudienceStatus
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mx-2",
                                                                      attrs: {
                                                                        color: _vm.$_dashMixin_taStatusColor(
                                                                          _vm
                                                                            .campaign
                                                                            .targetAudienceStatus
                                                                        )
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          _vm.$_dashMixin_getTAIcon(
                                                                            _vm
                                                                              .campaign
                                                                              .targetAudienceStatus
                                                                          )
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        201104070
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.campaign
                                                              .targetAudienceStatusDisplay ||
                                                              "Not selected"
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.campaign.nowwOrderNumber
                                                  ) +
                                                  "\n                  "
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.campaign &&
                                          _vm.campaign.targetAudienceId
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                  attrs: {
                                                                    color:
                                                                      "error"
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.unassignTA
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                        mdi-cancel\n                      "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1825484454
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Unassign Target Audience from Campaign"
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass: "black--text pt-3 pb-0"
                                        },
                                        [
                                          _c("TATable", {
                                            attrs: {
                                              items: _vm.tas,
                                              loading: _vm.taLoading,
                                              "no-actions": "",
                                              selectable: "",
                                              "show-number-of-resends": ""
                                            },
                                            on: {
                                              "item-selected": _vm.taSelected
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _vm.taRequiredSchedulingMailing
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.$emit("cancelStep", 3)
                                                      _vm.closeTADialog()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { left: "" } },
                                                    [
                                                      _vm._v(
                                                        "\n                      mdi-menu-left-outline\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                    Back\n                  "
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !_vm.taSelectionRequired
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: _vm.closeTADialog
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.campaign
                                                          .targetAudienceId
                                                          ? "Cancel"
                                                          : "I'll choose later"
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "v-btn",
                                                {
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$router.push({
                                                        name:
                                                          "CampaignDashboard"
                                                      })
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    Back to Campaigns\n                  "
                                                  )
                                                ]
                                              ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "success",
                                                disabled: !_vm.taIsSelected,
                                                loading: _vm.isLoading()
                                              },
                                              on: { click: _vm.updateTA }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Select\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                [
                  _c("CampaignDashboardItem", {
                    attrs: {
                      campaign: _vm.campaign,
                      "current-mailing-id": _vm.mailingItemId
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
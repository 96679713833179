<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="text-h6 py-2">
          <v-text-field
            v-model="campaignName"
            :disabled="disabled"
            :error-messages="campaignNameErrors"
            dense
            label="Campaign Name"
            data-wsc-autocreate="true"
            data-wsc-lang="en_US"
            class="input-max-width mr-6 mt-3"
            @click.native.stop
            @input="$v.campaignName.$touch()"
            @blur="$v.campaignName.$touch(); if (campaignName) setCampaignValue('campaignName')"
          />
          <v-select
            v-model="onBehalfOfNowwId"
            :disabled="disabled"
            dense
            hide-details
            :items="customerContacts"
            outlined
            label="Contact"
            color="primary"
            :loading="customerContactsLoading"
            class="input-max-width mr-6"
            @click.native.stop
            @blur="setCampaignValue('campaignOnBehalfOfNowwId')"
          />
          <span
            v-if="displayCustomerName"
            class="mr-4"
          >
            {{ getCampaignTenantName() }}
          </span>
          <v-spacer />
          <span
            class="d-flex justify-end"
          >
            <v-tooltip
              v-if="campaign && campaign.targetAudienceStatus"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  :color="$_dashMixin_taStatusColor(campaign.targetAudienceStatus)"
                  left
                  v-on="on"
                >
                  {{ $_dashMixin_getTAIcon(campaign.targetAudienceStatus) }}
                </v-icon>
              </template>
              <span>{{ campaign.targetAudienceStatusDisplay || 'Not selected' }}</span>
            </v-tooltip>
            <span
              v-if="campaign && campaign.nowwOrderNumber"
              class="mr-4"
            >
              {{ campaign.nowwOrderNumber }} <v-chip
                :color="$_dashMixin_fileTypeColor(campaign.targetAudienceFileType)"
                :dark="$_dashMixin_fileTypeColorDark(campaign.targetAudienceFileType)"
              >
                {{ campaign.targetAudienceFileType }}
              </v-chip>
            </span>
            <span
              v-if="campaign && campaign.campaignStatusDisplay === 'Draft'"
            >
              <v-dialog
                v-model="showTADialog"
                :persistent="taSelectionRequired"
              >
                <template v-slot:activator="{ on: onDialog }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        :color="campaign.targetAudienceId ? 'primary' : 'success'"
                        :disabled="isLoading()"
                        class="mr-4"
                        v-on="{ ...onTooltip, ...onDialog }"
                      >
                        <v-icon left>
                          mdi-bullseye-arrow
                        </v-icon>
                        {{ campaign.targetAudienceId ? 'Change' : 'Select' }} Target Audience
                      </v-btn>
                    </template>
                    <span>{{ campaign.targetAudienceId ? 'P.S. A Target Audience can only be changed while Campaign status is Draft' : 'P.S. A Target Audience must be selected before scheduling' }} </span>
                  </v-tooltip>
                </template>
                <v-card class="py-0">
                  <v-card-title
                    class="text-h6 grey lighten-2"
                    primary-title
                  >
                    Target Audiences
                    <v-spacer />
                    <span
                      v-show="campaign.nowwOrderNumber"
                      class="font-italic"
                    > Current:
                      <v-tooltip
                        v-if="campaign && campaign.targetAudienceStatus"
                        top
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon
                            :color="$_dashMixin_taStatusColor(campaign.targetAudienceStatus)"
                            class="mx-2"
                            v-on="on"
                          >
                            {{ $_dashMixin_getTAIcon(campaign.targetAudienceStatus) }}
                          </v-icon>
                        </template>
                        <span>{{ campaign.targetAudienceStatusDisplay || 'Not selected' }}</span>
                      </v-tooltip>
                      {{ campaign.nowwOrderNumber }}
                    </span>
                    <v-tooltip
                      v-if="campaign && campaign.targetAudienceId"
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="error"
                          class="mx-2"
                          v-on="on"
                          @click="unassignTA"
                        >
                          mdi-cancel
                        </v-icon>
                      </template>
                      <span>Unassign Target Audience from Campaign</span>
                    </v-tooltip>
                  </v-card-title>

                  <v-card-text
                    class="black--text pt-3 pb-0"
                  >
                    <!-- <v-row
                      v-if="showUploadCustomTA"
                    >
                      <v-col
                        cols="12"
                        class="d-flex justify-center pb-0"
                      >
                        <UploadTA
                          :campaign-id="campaignId"
                          :tenant-crm-id="getMailingTenantCrmId()"
                          @upload-success="uploadTASuccess()"
                        />
                      </v-col>
                    </v-row> -->
                    <TATable
                      :items="tas"
                      :loading="taLoading"
                      no-actions
                      selectable
                      show-number-of-resends
                      @item-selected="taSelected"
                    />
                  </v-card-text>

                  <v-divider />

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      v-if="taRequiredSchedulingMailing"
                      color="primary"
                      @click="$emit('cancelStep', 3); closeTADialog()"
                    >
                      <v-icon left>
                        mdi-menu-left-outline
                      </v-icon>
                      Back
                    </v-btn>
                    <v-btn
                      v-if="!taSelectionRequired"
                      color="primary"
                      @click="closeTADialog"
                    >
                      {{ campaign.targetAudienceId ? 'Cancel' : 'I\'ll choose later' }}
                    </v-btn>
                    <v-btn
                      v-else
                      color="primary"
                      @click="$router.push({ name: 'CampaignDashboard' })"
                    >
                      Back to Campaigns
                    </v-btn>
                    <v-btn
                      color="success"
                      :disabled="!taIsSelected"
                      :loading="isLoading()"
                      @click="updateTA"
                    >
                      Select
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </span>
          </span>
          <template v-slot:actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTooltip }">
                <v-icon
                  v-on="{ ...onTooltip }"
                >
                  $expand
                </v-icon>
              </template>
              <span>See details</span>
            </v-tooltip>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <CampaignDashboardItem
            :campaign="campaign"
            :current-mailing-id="mailingItemId"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mailingService, tenantService, nowwService } from '@/shared/services'
import { dashboardMixin } from '@/shared/mixins/mailing'
import { wscMixin, enumsMixin, downloadFileMixin } from '@/shared/mixins/general'
import { required } from 'vuelidate/lib/validators'
import { oneDriveMixin } from '@/shared/mixins/scripts'
import claims from '@/shared/models/auth/claims'
import Vue from 'vue'
import moment from 'moment'

export default {
  name: 'CampaignHeader',

  components: {
    CampaignDashboardItem: () => import('@/views/pages/components/mailing/CampaignDashboardItem'),
    TATable: () => import('@/views/pages/dashboards/TATable')
    // UploadTA: () => import('@/views/pages/components/mailing/UploadTA')
  },

  mixins: [dashboardMixin, wscMixin, enumsMixin, downloadFileMixin, oneDriveMixin],

  props: {
    campaignId: {
      type: String,
      required: true,
      default: ''
    },
    mailingItemId: {
      type: String,
      required: false,
      default: ''
    },
    stepCount: {
      type: Number,
      required: false,
      default: 1
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  validations: {
    campaignName: {
      required
    }
  },

  data () {
    return {
      campaign: null,
      customerContacts: [],
      customerContactsLoading: false,
      showTADialog: false,
      taLoading: false,
      tas: [],
      ta: null,
      taIsSelected: false
    }
  },

  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('auth', ['isMMSRole', 'getTenantCrmId']),
    ...mapGetters('mailing', ['getCampaignTenantName', 'getMailingTenantCrmId']),
    showUploadCustomTA () {
      return this.$store.getters['auth/hasClaimKV'](claims.MMS_TC)
    },
    campaignName: {
      get () {
        return this.$store.getters['mailing/getCampaignName']()
      },
      set (value) {
        this.$store.commit('mailing/setCampaignValue', { prop: 'campaignName', value })
      }
    },
    onBehalfOfNowwId: {
      get () {
        return this.$store.getters['mailing/getCampaignOnBehalfOfNowwId']()
      },
      set (value) {
        this.$store.commit('mailing/setCampaignValue', { prop: 'campaignOnBehalfOfNowwId', value })
      }
    },
    campaignNameErrors () {
      const errors = []
      if (!this.$v.campaignName.$dirty) return errors
      !this.$v.campaignName.required &&
        errors.push('Name is required')
      return errors
    },
    taSelectionRequired () {
      return this.taRequiredNonContracted || this.taRequiredSchedulingMailing
    },
    taRequiredNonContracted () {
      return !this.campaign || (!this.campaign.targetAudienceId && !this.campaign.isContracted)
    },
    taRequiredSchedulingMailing () {
      return !this.campaign || (!this.campaign.targetAudienceId && this.stepCount === 3)
    },
    displayCustomerName () {
      return this.isMMSRole() && this.getMailingTenantCrmId() !== this.getTenantCrmId()
    }
  },

  watch: {
    showTADialog (newValue, oldValue) {
      if (newValue) {
        this.loadUnassignedEmailTAs()
      }
    },
    campaignId (newValue, oldValue) {
      this.setCampaign()
    }
  },

  created () {
    if (this.campaignId) {
      this.setCampaign()
    }
  },

  methods: {
    async setCampaign () {
      if (this.campaignId === null) return
      var resp = await mailingService.getCampaignDashboardItem(this.campaignId)
      resp.modifiedOnLocal = moment(this.$_global_parseAsUtc(resp.modifiedOn)).local()
      if (resp.targetAudience) {
        resp.targetAudience.orderDateLocal = moment(this.$_global_parseAsUtc(resp.targetAudience.orderDate)).local()
      }
      Vue.set(this, 'campaign', resp)

      this.$emit('setCampaign', resp)
      await this.$store.dispatch('mailing/setCampaign', this.campaignId)
      this.loadCustomerContacts()

      if (this.taSelectionRequired) {
        this.showTADialog = true
      }
    },
    loadCustomerContacts () {
      this.customerContactsLoading = true
      tenantService
        .getContactsByTenantCrm(this.$store.getters['mailing/getMailingTenantCrmId']())
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
            this.customerContacts = []
          } else {
            this.customerContacts = resp.map(function (val, index) {
              return {
                text: val.name,
                value: val.contactNowwId,
                crmId: val.crmContactId
              }
            })
          }
        })
        .finally(() => {
          this.customerContactsLoading = false
        })
    },
    loadUnassignedEmailTAs () {
      this.taLoading = true
      nowwService
        .getUnassignedTAs(this.$store.getters['mailing/getMailingTenantCrmId']())
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
            this.tas = []
          } else {
            resp = resp.filter(x => x.numberOfResends + 1 >= this.$store.getters['mailing/getCampaignNumberOfBroadcasts']())
            if (this.taRequiredNonContracted) {
              this.tas = resp.filter(x => x.status === this.$_taStatus_Completed)
            } else {
              this.tas = resp
            }
          }
        })
        .finally(() => {
          this.taLoading = false
        })
    },
    setCampaignValue (prop) {
      this.$store.dispatch('mailing/setCampaignValue', { prop })
    },
    uploadTASuccess () {
      this.closeTADialog()
      this.$emit('setMailing', true)
    },
    closeTADialog () {
      this.showTADialog = false
    },
    async updateTA () {
      var model = {
        targetAudienceId: this.ta.id,
        campaignId: this.campaignId
      }
      await nowwService.setCampaignTA(model)
      this.closeTADialog()
      this.$emit('setMailing', true)
    },
    taSelected (val) {
      this.ta = val.item
      this.taIsSelected = val.value
    },
    async unassignTA () {
      var model = {
        targetAudienceId: this.$store.getters['mailing/getTargetId'](),
        campaignId: this.campaignId
      }
      await nowwService.unassignCampaignTA(model)
      this.closeTADialog()
      this.$emit('setMailing', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-progress-linear {
  display: inline-block;
  vertical-align: middle;
}

</style>
